/* eslint-disable react-hooks/rules-of-hooks */
import { SbComponent } from "@/components/sb-component";
import { useStoryblok } from "@/lib/storyblok/client";
import { Page } from "@/templates";
import { GetServerSideProps } from "next";
import absoluteUrl from "next-absolute-url";
import { assureTrailingSlash, isDetectDevice } from "utils";
import { dehydrate, QueryClient, useQuery } from "react-query";
import { getSlugStory } from "@/lib/storyblok";
import { stripQuery } from "@/utils";
import ErrorPage from "./_error";

export default function Slug({ queryName, preview, pageContext }) {
  const { language, resolvedUrl } = pageContext;
  const storyQuery = useQuery(
    [
      queryName,
      {
        language,
        resolvedUrl,
      },
    ],
    () => getSlugStory({ language, slug: resolvedUrl })
  );

  if (!storyQuery.data.story.content) {
    return <ErrorPage statusCode={404} />;
  }

  const dataStory = useStoryblok(storyQuery.data.story, preview);

  return (
    <>
      {dataStory?.content?.body?.map((blok) => {
        return <SbComponent blok={blok} key={blok._uid} />;
      })}
    </>
  );
}

Slug.getLayout = function getLayout(page) {
  const {
    props: { pageContext, preview, queryName },
  } = page;
  return (
    <Page pageContext={pageContext} preview={preview} queryName={queryName}>
      {page}
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  preview = true,
  locale,
  defaultLocale,
  resolvedUrl,
  req,
}) => {
  const queryName = "slug-page";
  const { origin } = absoluteUrl(req);
  const stripUrl = stripQuery(resolvedUrl);
  const language = locale || defaultLocale;

  const queryClient = new QueryClient();
  await queryClient.prefetchQuery(
    [
      queryName,
      {
        language,
        resolvedUrl: stripUrl,
      },
    ],
    () => getSlugStory({ language, slug: stripUrl })
  );

  console.log("SERVER PREVIEW:", preview);

  return {
    props: {
      queryName,
      dehydratedState: dehydrate(queryClient),
      preview,
      pageContext: {
        resolvedUrl: stripUrl,
        language: locale || defaultLocale,
        isMobile: isDetectDevice("mobile", req),
        seo: {
          defaultCanonicalUrl: assureTrailingSlash(origin + resolvedUrl),
        },
      },
    },
  };
};
